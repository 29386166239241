export const environment = {
  production: true,
  maintenance: false,
  mfaRequiredForAllUsers: true,
  localApiServer: false,
  idleTimeoutSeconds: 1200,
  debugUserIdle: false,
  debugUserTiming: false,
  debugRouter: false,
  awsRegion: 'us-west-2',
  sentryDsn: 'https://ed2619de3ed247f989f2054a43ee141c@sentry.io/1449789',
  captureApi: {
    url: 'https://t40b-r-prod.par8o.com/api/v1',
  },
  helpscoutApi: {
    token: '2ab8e508-c60b-4dc4-b370-e903091ced66',
  },
  pendo: {
    enabled: true,
    accountIdentifier: 'Production'
  },
  featureData: {
    consultNotePages: true,
    usePdfKitViewer: true,
  },
  psPdfKit: {
    licenseKey: 'ebSBYQJ_mreUpZhVEIfjya9ZoRUWXPJJfCemxLNRQXGZNS5jjT5j8DYNjQskYpCEwhvurBBuECV38dX9QFrNwVWyJ13oH6CDZql82zkLLszrHAxbdDO61zzC8eDNnBwjPC8Yb5DrvOUa-vi6M3pU6bOy7US2y4EIFiLxGU4u7p95oS-yYZ3DZrFLsnHnnkYqtpf7Gs-pwU_0Y9AF3doUxSD_WYj_GDhl2MWG4YQeBOrGqPA2hs-pjmAV9CC2RA1Do8rPLVjdlKbasUg2m-MruK7mAffs4rXpI79DmcjJVkXlufx62ASMkKBdth_taYKLzbUWOHvOxo4SkUYWMB_sTGmQ5Pifj9i4l7HSLcvsXwPKLL8atTiKR4WDFCr0DlTyG3_zMxqQ9TWs6nP8MeJJEVZlkNCaHSk1Z-4HcIFGMDC6aJRIL6habMwS2ooEalkrBljPofkqcNPW2vRVoU9Fda16dWkbIrsfXR8QWuMlWLapfY6Y5E-viX1qaSgHUMMPq4xHyPhjwP2Ee4_fQl3DOgIXbq3V86-KuUHgldiiF017PEugFnXwmhCOofIhjmbIanmMBnz2tW3pT9_7yS3SlmBNhaOyAX7aq3hSYDhytlU='
  },
};
