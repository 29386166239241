<ng-container *ngIf="!loading; else: spinner">
  <app-patient-header [patient]="patient" />
    <h6 class="card-title m-0 px-2 py-1 d-flex">
      <mat-icon>event</mat-icon>
      <div class="ms-1 pt-1">Patient Encounters</div>
    </h6>

    <div class="mt-3">
      <app-encounter-list
      [fetchEncounters]="fetchPatientEncounters"
      [eligibleCount]="patient.eligibleEncountersCount"
      [ineligibleCount]="patient.ineligibleEncountersCount"
    />
    </div>
</ng-container>
<ng-template #spinner> <mat-spinner /> </ng-template>
