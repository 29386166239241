<div class="modal-header">
  <h4 class="modal-title">Edit Consult Note Specialty</h4>
</div>
<div class="modal-body">
  <div>Update the medical specialty of this consult note:</div>
  <ng-select
    placeholder="Choose a specialty"
    class="mb-3 mt-1"
    [items]="referralSpecialties"
    bindValue="name"
    bindLabel="name"
    [(ngModel)]="patientAttachment.selectedSpecialty"
  />
  <app-additional-details-input
    id="selectedSpecialtyAdditionalDetails"
    [(value)]="patientAttachment.selectedSpecialtyAdditionalDetails"
  />
  <div class="mt-3 p-2 rounded text-bg-light">
    <div>Providers with a verification related to this consult note:</div>
    <ng-container *ngIf="!loadingPrescribers; else: spinner">
      <div class="d-flex flex-row flex-wrap">
        <div *ngFor="let prescriber of uniquePrescribers" class="m-2">
          <app-prescriber-name [prescriber]="prescriber" [showSpecialtiesDetails]="true" />
        </div>
      </div>
    </ng-container>
    <ng-template #spinner><mat-spinner diameter="30" /></ng-template>
  </div>
</div>
<div class="modal-footer">
  <button type="button" class="btn btn-secondary" (click)="onCancelClick()">Cancel</button>
  <button type="button" class="btn btn-primary" (click)="onSaveClick($event)">Save</button>
</div>
